import { Canvas } from '@react-three/fiber'
import { Suspense, useCallback, useState } from "react";
import { OrbitControls } from '@react-three/drei'
import { Leva, useControls, button } from 'leva'
import TeamMember from './TeamMember.js'

export default function App() {
	const [instancesNb, setInstancesNb] = useState(1);

	const config = useControls({
		ambientLight: {
			value: 6,
			min: 0,
			max: 10
		},
		helpers: true,
		autoRotateSpeed: {
			value: 0,
			min: -20,
			max: 20,
			label: "Auto rotate"
		},
		background: {
			value: '#000000'
		},
		add: button((get) => {
			console.log('add ', instancesNb);
			setInstancesNb(instancesNb+1)
		}),
		remove: button((get) => {
			setInstancesNb(instancesNb-1);
		}, {
			disabled: instancesNb == 0
		})
	}, [instancesNb])

	const instances = [];
	for(let i = 0; i < instancesNb; i++) {
		instances.push(
			<Suspense fallback={null} key={i}>
				<TeamMember label={'Model #'+i} />
			</Suspense>
		)
	}

	return (
		<>
			<Canvas camera={{position: [2,2,6], fov: 30 }} >
				<color attach="background" args={[config.background]} />
				<ambientLight intensity={config.ambientLight} />
				{instances}
				<OrbitControls target={[0,.7,0]} autoRotate={true} autoRotateSpeed={config.autoRotateSpeed} />
				{ config.helpers &&
					<>
					{/* <axesHelper args={[5]} /> */}
					<gridHelper args={[20, 20, 0x333333, 0x111111]} />
					</>
				}
			</Canvas>
			<Leva />
		</>
	)
}
